import type { ReactNode } from 'react'
import { useCallback, useState } from 'react'

import { ease } from '@nick-mazuk/lib/animation'
import { Ratio } from '@nick-mazuk/ui/elements/ratio'
import styles from 'components/scss/laptop.module.scss'
import { motion } from 'framer-motion'
import { InView } from 'react-intersection-observer'

type Properties = {
    children?: ReactNode | ReactNode[]
    animate?: boolean
}

export const Laptop = ({ children, animate = false }: Properties): JSX.Element => {
    const [open, setOpen] = useState(!animate)
    const lidVariants = {
        hidden: { rotateX: -86, perspective: 2000 },
        visible: { rotateX: 0, perspective: 2000 },
    }
    const shadowVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 0.3 },
    }

    const handleInView = useCallback(
        (inView: boolean): void => {
            if (animate) setOpen(inView)
        },
        [animate]
    )

    return (
        <InView onChange={handleInView} triggerOnce>
            <div className={styles.container}>
                <div className={styles.laptop}>
                    <motion.span
                        variants={shadowVariants}
                        animate={open ? 'visible' : 'hidden'}
                        transition={{ duration: 2 }}
                        initial={animate ? 'hidden' : 'visible'}
                        className={styles.shadow}
                    />
                    <motion.span
                        variants={lidVariants}
                        animate={open ? 'visible' : 'hidden'}
                        transition={{ duration: 2, ease: ease['ease-out-cubic'] }}
                        initial={animate ? 'hidden' : 'visible'}
                        className='inset-0 origin-bottom'
                    >
                        <span className={styles.lid} />
                        <span className={styles.screen}>
                            <Ratio ratio='screen'>{children}</Ratio>
                        </span>
                    </motion.span>
                    <span className={styles.chassis}>
                        <span className={styles.keyboard} />
                        <span className={styles.trackpad} />
                    </span>
                </div>
            </div>
        </InView>
    )
}

export type Laptop = ReturnType<typeof Laptop>
